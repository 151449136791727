import React from 'react';
import '../../assets/css/bootstrap/bootstrap.css';

import {Col, Container, Row} from 'react-bootstrap';
import ArtistImg from '../../assets/img/AdamSuski-about-img.jpg';

function About() {
  return (
      <div id="about" className="main-info">
        <Container>
        <Row>
          <Col xs={12} md={6}>
            <h1 className="fw-special-title animated animatedMain blurInRight full-visible about-title">Rzeźbiarstwo - Adam Suski</h1>
            <p className="text animated animatedMain blurInRight full-visible">
              Początek mojej przygody z rzeźbiarstwem zawdzięczam mojemu dziadkowi. Można powiedzieć, że odziedziczyłem po nim 
              zamiłowanie do pracy z drewnem. Przez wiele lat wykonywał różne przedmioty głównie związane z rolnictwem np. wozy, brony, 
              pługi. Zdarzały się także zlecenia odnawiania elementów drewnianych. Do dzisiaj pamiętam kolorowe koniki z karuzeli. 
              Z chęcią pomagałem dziadkowi przy pracach.
              </p>
              <p className="animated animatedMain blurInRight full-visible">
                Moim pierwszym miejscem pracy było Polskie Towarzystwo Turystyczno-Krajoznawcze, gdzie wykonywałem drewniane kasetki. 
                Z biegem latem rozwijałem swój warsztat, na początku pojawiły się rzeźbione krzyże, maselniczki, następnie szyldy. 
                Gdy już osiągnąłem odpowiedni etap w swojej karierze, rozpocząłem działalność na własną rękę.
              </p>
              <p className="animated animatedMain blurInRight full-visible">
                  Była to bardzo dobra decyzja. Ruszyłem z dekoracjami mebli stylowych, tworzenie płaskorzeźb, rzeźb wolnostojących, 
                  a późniejszym czasie także rekonstrukcjami zabytków.<br/>Współpracuje zarówno z większymi firmami, jak i klientami 
                  indywidualnymi. To właśnie różne indywidualne projekty sprawiają mi największą radość, poczucie spełnienia 
                  artystycznego.
              </p>
          </Col>
          <Col className="text-center" xs={12} md={6}>
          <img className="animated animatedMainImg blurInLeft img-fluid" src={ArtistImg} alt="Rzeźbiarz Adam Suski z rzeźbą żubra"/>
          </Col>
        </Row>
        </Container>
      </div>
  );
}

export default About;
