import React from 'react';
import '../../assets/css/bootstrap/bootstrap.css';
import logoImg from '../../assets/img/logo-emb.png';
import {Container, Row, Col} from 'react-bootstrap';
import instagram from '../../assets/img/instagram-brands.svg';
import Copyright from '../../components/Copyright/Copyright';

function Footer() {
  return (
    <footer id="contact">
      <Container className="footer-box">
        <Row>
          <Col className="text-center" xl={6}>
            <img className="footer-logo" src={logoImg} alt="Logo Rzeźbiarstwo Suski" />
            <h3>RzeźbiarstwoSuski.com.pl</h3>
            <ul className="footer-items">
              <li>Adam Suski</li>
              <li>Grzechynia 651</li>
              <li><a href="tel:+48504004496">504 004 496</a></li>
              <li><a href="mailto:adamsuski1@wp.pl">adamsuski1@wp.pl</a></li>
              <li>
              <a class="social-box" href="https://www.instagram.com/rzezbiarstwosuski.com.pl/" 
              target="_blank" rel="noopener noreferrer"><img className="footer-instagram" src={instagram} alt="Logo Instagram" /></a>
              </li>
            </ul>
          </Col>

          <Col className="text-center" xl={6}>
          <iframe 
            title="Google Maps - Grzechynia 651" 
            data-lazyloaded="1" 
            src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d2459.92908933737!2d19.651528301183934!3d49.7142587388753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m5!1s0x4716791023df878d%3A0x84832b37e726432d!2sGrzechynia%20651%2C%20Grzechynia!3m2!1d49.714313!2d19.651895!4m0!5e1!3m2!1spl!2spl!4v1574107258696!5m2!1spl!2spl" 
            data-src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d2459.92908933737!2d19.651528301183934!3d49.7142587388753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m5!1s0x4716791023df878d%3A0x84832b37e726432d!2sGrzechynia%20651%2C%20Grzechynia!3m2!1d49.714313!2d19.651895!4m0!5e1!3m2!1spl!2spl!4v1574107258696!5m2!1spl!2spl" 
            allowfullscreen="" 
            data-was-processed="true" 
            className="footer-map"
            frameborder="0"
            style={{ border: 0 }}>
            </iframe>
          </Col>

          </Row>
          </Container>
          
          <Copyright />
          
    </footer>
  );
}

export default Footer;
