import React from 'react';
import Header from './components/Header/Header';
import About from './components/About/About';
import Services from './components/Services/Services';
import Gallery from './components/Gallery/Gallery';
import Footer from './components/Footer/Footer';
import Preloader from './components/Preloader/Preloader';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';


import logo from './assets/img/logo-emb.png';
import favi from './assets/img/favicon.ico';
import './assets/css/animate.css';
import './assets/css/cssanimation.css';
import './assets/css/custom.scss';

ReactGA.initialize('UA-148569592-5');
ReactGA.pageview(window.location.pathname + window.location.search);

function App () {
  return (
    <>
    
    <Helmet>
    <title>Rzeźbiarstwo Adam Suski Grzechynia</title>
    <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta2/css/all.min.css" rel="stylesheet" />
    <meta name="description" content="Adam Suski, rzeźbiarz z Grzechyni. Zobacz jego historię, szeroki zakres usług oraz realizacje. Rzeźbione meble, płaskorzeźby, rzeźby wolnostojące." data-react-helmet="true" />
    <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"></meta>
    <link rel="icon" type="image/png" href={favi}></link>

<script async src="https://www.googletagmanager.com/gtag/js?id=UA-148569592-5"></script>

    </Helmet>

    
    <Header />
    <About />
    <Services />
    <Gallery />
    <Footer />
    <Preloader img={logo} />
    
    </>
  );
}

export default App;
