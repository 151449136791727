import React, { Component } from 'react';
import '../../assets/css/bootstrap/bootstrap.css';
import { $ }  from 'react-jquery-plugin';

import Overlay from '../../assets/js/vegas/overlays/07.png';
import Slide1 from '../../assets/img/slider1.jpg';
import Slide2 from '../../assets/img/slider2.jpg';
import Slide3 from '../../assets/img/slider3.jpg';

import '../../assets/js/vegas/vegas.js';
import '../../assets/js/vegas/vegas.min.css';
class Header extends Component {
  componentDidMount() {
    var navButton = document.querySelector('.navigation-button');
    var navMenu = document.querySelector('.navigation-menu');
    var win = window;

    function openMenu(event) {
    
      navButton.classList.toggle('active');
      navMenu.classList.toggle('active');
  
      event.preventDefault();
      event.stopImmediatePropagation();
    }
      
    function closeMenu(event) {
      if (navButton.classList.contains('active')) {
        navButton.classList.remove('active');
        navMenu.classList.remove('active');
      }
    }
      navButton.addEventListener('click', openMenu, false);
  
    win.addEventListener('click',closeMenu, false);
    
    $("#hero").vegas({
      slides: [
          { src: Slide1 },
          { src: Slide2 },
          { src: Slide3 }
      ],
      animation: 'random',
      overlay: {Overlay},
      transitionDuration: 1000,
      delay: 7000,
      cover: true
    });
  }
  render () {
  return (
    <>
      <header id="masthead" className="site-header" role="banner">

      <div className="navigation-wrapper">
        <div className="navigation-button">
            <i className="fa fa-bars"></i>
        </div>

  <div className="navigation-menu">
        <ul className="ps-navbar">
          <li><a href="#about">O mnie</a></li>
          <li><a href="#gallery">Galeria</a></li>
          <li><a href="#contact">Kontakt</a></li>
          <li><a href="tel:+48504004496">
          <i className="fa-icon fas fa-phone-alt"></i>
            </a></li>
          <li><a href="https://www.instagram.com/rzezbiarstwosuski.com.pl/" 
              target="_blank" rel="noopener noreferrer"><i className="fa-icon fab fa-instagram"></i></a></li>
        </ul>
  </div>
</div>

</header>
<div id="hero">
		<div className="logo-wrapper">
			<div className="page-logo flex-center">
				<div className="page-title cssanimation leBlurIn random">Rzeźbiarstwo Suski</div>
			</div>
		</div>
	</div>
  </>
  );
}
}

export default Header;
