import React from 'react';
import '../../assets/css/bootstrap/bootstrap.css';

import {Col, Container, Row} from 'react-bootstrap';

function Copyright() {
  return (
      <div className="copyright">
        <Container>
        <Row>
          <Col>
          <h6>Copyright  Rzeźbiarstwo Adam Suski - 2021</h6>
          </Col>
          <Col className="text-right">
            <h6>created by <a href="https://suskiproweb.pl" target="_blank" rel="noreferrer">SuskiPROWEB</a></h6>
          </Col>
        </Row>
        </Container>
      </div>
  );
}

export default Copyright;
