import React from 'react';
import '../../assets/css/bootstrap/bootstrap.css';
import ReactImageGallery from 'react-image-gallery';

import {Col, Container, Row} from 'react-bootstrap';

import img1 from '../../assets/img/1.jpg';
import img3 from '../../assets/img/3.jpg';
import img5 from '../../assets/img/5.jpg';
import img6 from '../../assets/img/6.jpg';
import img7 from '../../assets/img/7.jpg';
import img8 from '../../assets/img/8.jpg';

const images = [
  {
    original: img1,
    thumbnail: img1
  },
  {
    original: img3,
    thumbnail: img3
  },
  {
    original: img5,
    thumbnail: img5
  },
  {
    original: img6,
    thumbnail: img6
  },
  {
    original: img7,
    thumbnail: img7
  },
  {
    original: img8,
    thumbnail: img8
  },

]

function Gallery() {
  return (
      <div id="gallery" className="main-info">
        <Container>
        <Row>
          <Col>
            <h2 className="fw-special-title animated animatedMain blurInRight full-visible services-title">Moje Realizacje</h2>
            <ReactImageGallery items={images} />
            <div class="divider"></div>
          </Col>
        </Row>
        </Container>
      </div>
  );
}

export default Gallery;
