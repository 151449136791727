import React from 'react';
import '../../assets/css/bootstrap/bootstrap.css';
import { CustomPreloader } from 'react-preloaders2';


function Preloader(props) {
  return (
    <CustomPreloader background={'#000'} time={1200} >
      <div className="d-flex justify-content-center align-items-center">
        <img src={props.img} className="preloader-img" />
      </div>
    </CustomPreloader>
  );
}

export default Preloader;
