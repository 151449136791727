import React from 'react';
import '../../assets/css/bootstrap/bootstrap.css';

import {Col, Container, Row} from 'react-bootstrap';

function About() {
  return (
      <div id="services" className="main-info">
        <Container>
        <Row>
          <Col>
            <h2 className="fw-special-title animated animatedMain blurInRight full-visible services-title">Wykonuję</h2>
            <ul className="list">
              <li className="animated animatedList moveFromLeft full-visible">Płaskorzeźby</li>
              <li className="animated animatedList moveFromLeft full-visible">Rzeźby wolnostojące</li>
              <li className="animated animatedList moveFromLeft full-visible">Dekoracja elementów mebli</li>
              <li className="animated animatedList moveFromLeft full-visible">Rekonstrukcja elementów zabytkowych</li>
              <li className="animated animatedList moveFromLeft full-visible">Szopki bożonarodzeniowe</li>
              <li className="animated animatedList moveFromLeft full-visible">Krzyże</li>
              <li className="animated animatedList moveFromLeft full-visible">Rzeźbione ramy obrazów</li>
              <li className="animated animatedList moveFromLeft full-visible">Ornamenty</li>
              <li className="animated animatedList moveFromLeft full-visible">i wiele wiele innych</li>
            </ul>
          </Col>
        </Row>
        </Container>
      </div>
  );
}

export default About;
